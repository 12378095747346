*::-webkit-scrollbar {
  display: none !important;
}
.App {
  text-align: center;
  color: white;
  background: black;
  height: max-content;
  align-items: center;
  font-size: 18px;
  font-family: "Source Sans 3" !important;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
}
p {
  margin-bottom: 0px;
}

.hidden {
  opacity: 0;
  transition: all 3s;
}

.show {
  opacity: 1;
}

@media (max-width: 1024) {
  .App {
    padding: 0 2%;
    width: fit-content;
  }
}

/* Animations */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-30%);
    opacity: 0;
  }
}

@keyframes scale {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.03, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes flip {
  0% {
    transform: translateY(0);
  }

  33% {
    transform: translateY(-100%);
  }

  66% {
    transform: translateY(-170%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes zoom-in {
  100% {
    scale: 100%;
  }
  50% {
    scale: 120%;
  }
  0% {
    scale: 120%;
  }
}

@keyframes scroll-horizontal {
  0% {
    left: 100%;
    overflow: hidden;
  }
  100% {
    left: -100%;
    overflow: hidden;
  }
}
